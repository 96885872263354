import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { BrowserModule } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter, withComponentInputBinding } from '@angular/router';
import { FeatherModule } from 'angular-feather';
import {
  AlertCircle,
  AlertTriangle,
  ArrowLeft,
  ArrowRight,
  Calendar,
  Check,
  CheckCircle,
  ChevronDown,
  ChevronUp,
  ChevronRight,
  Clock,
  CreditCard,
  Download,
  Edit,
  Eye,
  EyeOff,
  FilePlus,
  FileText,
  Info,
  Layers,
  Mail,
  MapPin,
  Menu,
  Phone,
  Plus,
  Printer,
  Settings,
  Star,
  Trash2,
  Upload,
  User,
  UserPlus,
  Users,
  X,
  List,
} from 'angular-feather/icons';
import { provideEnvironmentNgxMask } from 'ngx-mask';
import routes from './app.routes';
import { authInterceptor } from './core/interceptors/auth/auth.interceptor';
import { generalInterceptor } from './core/interceptors/general/general.interceptor';
import { GeneralCoreService } from './core/services/general-core/general-core.service';

const icons = {
  ChevronRight,
  Menu,
  X,
  Upload,
  ChevronDown,
  ChevronUp,
  FileText,
  Settings,
  CreditCard,
  AlertCircle,
  Phone,
  Mail,
  MapPin,
  CheckCircle,
  ArrowRight,
  Layers,
  Check,
  ArrowLeft,
  Info,
  User,
  AlertTriangle,
  Calendar,
  Printer,
  Clock,
  Plus,
  Trash2,
  UserPlus,
  Eye,
  EyeOff,
  Download,
  Users,
  Star,
  Edit,
  FilePlus,
  List,
};

export const appConfig: ApplicationConfig = {
  providers: [
    importProvidersFrom(
      BrowserModule,
      MatDialogModule,
      FeatherModule.pick(icons),
      GeneralCoreService
    ),
    provideHttpClient(withInterceptors([authInterceptor, generalInterceptor])),
    provideRouter(routes, withComponentInputBinding()),
    provideEnvironmentNgxMask(),
    provideAnimations(),
  ],
};
